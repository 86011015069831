import React from 'react';

const Footer = () => {
	return (
		<div className='Footer'>
			<h6 className='footer-text'>Copyright © Daniel Trauten - 2023</h6>
		</div>
	);
};

export default Footer;
