import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';

const Cards = () => {
	return (
		<>
			<Container>
				{/* <Row>
					<Col> */}
						<Card>
							<Card.Body>
								<Card.Title>SNES Randomizer</Card.Title>
								<Card.Subtitle>HTML • CSS • JS</Card.Subtitle>
								<Card.Text>
									Lightweight website that pulls data from randomly
									selected Super Nintendo games using the RAWG API -
									displaying that info for the viewer.
								</Card.Text>
								<ButtonGroup className='button-group' size='sm'>
									<Button
										href='http://snes-randomizer.dantrauten.com/'
										variant='dark'
									>
										Demo
									</Button>
									<Button
										href='https://github.com/trautfish/snes-randomizer'
										variant='dark'
									>
										GitHub
									</Button>
								</ButtonGroup>
							</Card.Body>
						</Card>
					{/* </Col>
					<Col> */}
						<Card>
							<Card.Body>
								<Card.Title>Boss Monster Bagels</Card.Title>
								<Card.Subtitle>React • Tailwind CSS</Card.Subtitle>
								<Card.Text>
									Menu and online ordering website for local,
									freshly-baked NY-style bagels with the options for
									pickup and delivery.
								</Card.Text>
								<ButtonGroup className='button-group' size='sm'>
									<Button
										href='http://bossbagels-react.dantrauten.com/'
										variant='dark'
									>
										Demo
									</Button>
									<Button
										href='https://github.com/trautfish/bossbagels-react'
										variant='dark'
									>
										GitHub
									</Button>
								</ButtonGroup>
							</Card.Body>
						</Card>
					{/* </Col>
					<Col> */}
						<Card>
							<Card.Body>
								<Card.Title>Nucamp Project Site</Card.Title>
								<Card.Subtitle>React • CSS • Redux</Card.Subtitle>
								<Card.Text>
									Responsive, REST-ful campsite reservation web app
									created during the Nucamp Front-End Web Development
									Bootcamp in 2023.
								</Card.Text>
								<ButtonGroup className='button-group' size='sm'>
									<Button
										href='http://nucampsite-react.dantrauten.com/'
										variant='dark'
									>
										Demo
									</Button>
									<Button
										href='https://github.com/trautfish/nucampsite-react'
										variant='dark'
									>
										GitHub
									</Button>
								</ButtonGroup>
							</Card.Body>
						</Card>
					{/* </Col>
				</Row> */}
			</Container>
		</>
	);
};

export default Cards;
