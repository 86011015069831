import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Icon } from '@iconify/react';
import logo from './images/logo-portrait.jpg';
import galleryImage1 from './images/ronin-export-high.jpg';
import galleryImage2 from './images/advtimefinal.jpg';
import galleryImage3 from './images/lydia_portrait_web.jpg';
import WSPGallery from './components/WSPGallery';
import Button from 'react-bootstrap/Button';
import Footer from './components/Footer';
import Cards from './components/Cards';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function App() {
	const menuItems = [
		{
			id: 1,
			title: 'About',
		},
		{
			id: 2,
			title: 'Work',
		},
		{
			id: 3,
			title: 'Contact',
		},
	];

	const galleryImages = [
		{ img: galleryImage2 },
		{ img: galleryImage1 },
		{ img: galleryImage3 },
	];

	return (
		<>
			<div className='App'>
				<header>
					<nav>
						<h4 onClick={scroll.scrollToTop}>Dan Trauten</h4>

						<ul>
							{menuItems.map((menu) => (
								<li>
									<Link
										to={menu.id}
										smooth={true}
										offset={-90}
										duration={600}
									>
										{menu.title}
									</Link>
								</li>
							))}
						</ul>
					</nav>
				</header>

				<main>
					<div className='content-home'>
						<div>
							<img
								className='logo'
								src={logo}
								alt='Portrait of Dan Trauten'
							/>
						</div>
						<h1 className='home-title'>Dan Trauten</h1>
						<div class='divider-custom-line'></div>
						<h4 className='home-subtitle'>
							Frontend Web Developer - Illustrator
						</h4>
					</div>

					<div className='content-about' id='1'>
						<h2 className='about-title'>About</h2>
						<div class='divider-custom-line-dark'></div>
						<h5 className='about-text'>
							Hi!
							<br />
							<br />
							My name's Dan, and I'm a Chicago-based Frontend Web
							Developer and Illustrator. I enjoy creating engaging web
							content utilizing my many years of experience as a working
							artist.
							<br />
							<br />
							I’m always looking to work with other collaborators to
							create accessible, human-centered web experiences.
						</h5>
					</div>

					<div className='content-work' id='2'>
						<h2 className='work-title'>Work</h2>
						<div class='divider-custom-line'></div>

						<Cards></Cards>

						<h5 className='work-text'>
							I have many years of experience as a Freelance Illustrator
							working in digital and traditional media, with a focus on
							visual storytelling. I’ve also designed visual assets for
							several iOS apps, including advertisements and games.
						</h5>
						<WSPGallery
							galleryImages={galleryImages}
							className='gallery-content'
						/>
					</div>
					<div className='content-contact' id='3'>
						<h2 className='contact-title'>Contact</h2>
						<div class='divider-custom-line-dark'></div>
						<div className='contact-container'>
							<h5>
								<a href='mailto: dan.trauten@gmail.com'>
									dan.trauten@gmail.com
								</a>
							</h5>

							<a href='https://docs.google.com/document/d/1hNxlhBsEFLyJGwmAuDAIxBsR17_V3kddGkCeMlSk8ic/edit?usp=sharing'>
								<Button className='resume-btn' variant='dark'>
									Resume
								</Button>
							</a>

							<div className='work-links'>
								<a href='http://www.github.com/trautfish'>
									<span class='work-icons'>
										<Icon
											icon='fa6-brands:github'
											style={{ height: 53, width: 53 }}
										></Icon>
									</span>
								</a>
								<a href='https://www.linkedin.com/in/dantrauten/'>
									<Icon
										icon='fa6-brands:linkedin'
										style={{ height: 53, width: 53 }}
									></Icon>
								</a>
								<a href='https://www.instagram.com/dantrauten/'>
									<Icon
										icon='fa6-brands:square-instagram'
										style={{ height: 53, width: 53 }}
									></Icon>
								</a>
							</div>
						</div>
					</div>
				</main>
			</div>

			<Footer />
		</>
	);
}

export default App;
